@import "../../global.scss";

.giftCard_content {
    text-align: center;
    background: white;
    padding: 0 0.5rem;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;
}

.giftCard_headerFull {
    min-height: unset !important;
}

.giftCard_header {
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1.5rem !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h2 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    .newGiftCardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .message {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
            font-weight: 800;
        }

        .message2 {
            font-size: 0.8rem;
            color: #666;
            font-weight: 800;
            margin-bottom: 0.25rem;
        }

        .message3 {
            font-size: 0.775rem;
            color: #666;
            font-weight: 800;
            margin-bottom: 0.25rem;
        }

        .giftCard_codeContainer {
            background-color: #ddd;
            border: 1px solid gray;
            padding: 2rem 0.25rem;
            margin-top: 0.25rem;
            border-radius: 10px;
            width: 100%;
            max-width: 400px;

            .giftCard_code {
                font-weight: 900;
                font-size: 1.75rem;
                margin-bottom: 0.5rem;
                text-decoration: underline;
                word-break: break-all;
            }

            .giftCard_value {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
        }

        .img_container {
            height: 160px;
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                height: 160px;
            }
        }
    }

    .giftcard-radio {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .radio-labels {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        min-width: 80px;

        label {
            font-size: 1.35rem;
            margin: 0;
        }

        input {
            margin: 0;
            width: 24px;
            height: 24px;
        }
    }

    .amount_input {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 1rem;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
        border: 1px solid #e6e6e6;
        padding: 0.75rem;
        border-radius: 5px;
        font-size: 1rem;
    }

    .getgcbalance_button {
        width: fit-content;
        min-width: 150px;
        margin: 1rem auto;
        padding: 0;
        cursor: pointer;

        button {
            width: 100%;
            padding: 12px 24px;
            font-size: 1.1rem;
            font-weight: 600;
            color: white;
            background: linear-gradient(135deg, #567c4b 0%, #3d5836 100%);
            border: none;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                background: linear-gradient(135deg, #628e56 0%, #456340 100%);
            }

            &:active {
                transform: translateY(1px);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .giftCard_balance {
        margin-top: 1rem;
        font-size: 1.125rem;
        font-weight: 600;
    }
}

.checkout_container {
    background-color: #eee;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #ccc;

    .add_payment_method {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(185, 185, 185, 0.5);
        border-bottom: 1px solid rgba(185, 185, 185, 0.5);
        padding: 10px;

        .add_paymentMethodTitle {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 600;
        }

        svg {
            height: 26px;
            width: 26px;
        }

        .pay_later_checkbox {
            height: 24px;
            width: 24px;
        }
    }

    .payment_profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid black;
        border-radius: 5px;
        padding: 0.75rem 1.5rem;
        margin: 0.75rem 0;
        background-color: $mainGray;

        div {
            font-size: 18px;
            font-weight: 700;
        }
    }

    .Active {
        background-color: $mainRedLight;
        border: 4px solid $mainRed;
        color: white;

        div {
            font-size: 20px !important;
        }
    }

    .checkout_button {
        align-items: center;
        background-color: $mainGreenLight;
        border-radius: 10px;
        border: 5px solid $mainGreen;
        color: #fff;
        display: flex;
        font-size: 1.25rem;
        font-weight: 900;
        height: 4rem;
        justify-content: center;
        margin: 1rem 0;
        width: 100%;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #218838;
        }
    }

    .invalid {
        align-items: center;
        background-color: $mainGreen;
        border-radius: 10px;
        border: 5px solid $mainGreen;
        color: #fff;
        display: flex;
        font-size: 1.25rem;
        font-weight: 900;
        height: 4rem;
        justify-content: center;
        margin: 1rem 0;
        width: 100%;
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.payment_inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    label {
        text-align: left;
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        color: #444;
        font-weight: 700;
        margin-top: 1rem;
    }

    input {
        margin-bottom: 1rem;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
            0px 3px 6px rgba(0, 0, 0, 0.02);
        border: 1px solid #e6e6e6;
        padding: 0.75rem;
        border-radius: 5px;
        font-size: 1rem;
    }
}

// Media queries for different screen sizes
@media screen and (max-width: 480px) {
    .giftCard_header {
        h2 {
            font-size: 1.5rem;
        }

        .giftcard-radio {
            gap: 0.5rem;
        }

        .radio-labels {
            min-width: 60px;

            label {
                font-size: 1.2rem;
            }

            input {
                width: 20px;
                height: 20px;
            }
        }

        .amount_input {
            padding: 0.5rem;
        }

        .newGiftCardContainer {
            .giftCard_codeContainer {
                .giftCard_code {
                    font-size: 1.25rem;
                }

                .giftCard_value {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .giftCard_header {
        padding: 2rem;
    }
}