@import "../../global.scss";

.menu {
  .itemDescription {
    font-size: 1.1rem;
    font-weight: 700;
    color: #666;
  }
  .itemDays {
    font-size: 1rem;
    font-weight: 700;
    color: #999;
  }
  .itemPrice {
    font-size: 1.2rem;
  }
  .itemName {
    font-size: 1.3rem;
    margin: 0.25rem 0;
  }
}

.menu_categories_container {
  display: flex;
  justify-content: center;
  cursor: pointer;

  .scroll_button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 2rem;
      width: 2.5rem;
    }
  }

  .menu_categories {
    display: flex;
    flex: 13 1;
    overflow-x: auto;
    z-index: 1;

    .Active {
      background-color: $mainRedLight;
      border: 2px solid $mainRed;
      color: #fff;
      font-weight: 600;
    }
  }
}

.menu_sub_categories_container {
  display: flex;
  .menu_sub_categories {
    display: flex;
    overflow-x: auto;
    .sub_category {
      display: flex;

      .Active {
        background-color: $mainRedLight;
        border: 2px solid $mainRed;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.menu_items {
  text-align: center;

  .categoryDescription {
    background: $mainGray;
    margin: auto;
    padding: 6px 12px;
    border-radius: 5px;
    border: 1px solid gray;
    color: gray;
    font-weight: 700;
    text-align: center;
    font-size: 0.9rem;
    max-width: 90%;
    display: inline-block;
  }

  .card{
    text-align: left;
    margin: 0.3rem 0.5rem;
    cursor: pointer;
  }
}

.menu_category_button {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  color: #000;
  font-size: 0.75rem;
  margin-right: 0.175rem;
  padding: 10px 6px;
  white-space: nowrap;
  border: 1px solid black;
  font-size: 1.05rem;
}

.subcategory {
  padding: 10px 6px;
  font-size: 0.95rem;
  margin: 0 0.1rem;
}

.menu_search {
  border: 2px solid rgb(158, 158, 158);
  border-radius: 5px;
  align-items: center;
  background-color: rgb(232, 232, 232);
  margin: 0 0.3rem;

  svg {
    height: 24px;
    width: 24px;
    margin: 0 2px;
    padding-bottom: 4px;
  }

  input {
    border: none;
    border-left: 1px solid rgb(158, 158, 158);
    padding: 0.4rem;
    font-size: 18px;
    width: 100%;
    padding-left: 0.5rem;
  }
}

.css-13cymwt-control {
  height: 3rem !important;
}

@include phone {
  .menu {
    .itemDescription {
      font-size: 0.8rem;
    }
    .itemDays {
      font-size: 0.775rem;
    }
    .itemPrice {
      font-size: 1rem;
    }
    .itemName {
      font-size: 1.05rem;
      margin-bottom: 0.25rem;
    }
  }
  .menu_categories_container {
    .scroll_button {
      svg {
        height: 2rem;
        width: 1.1rem;
      }
    }
  }
  .menu_category_button {
    font-size: 13px;
    padding: 8px 4px;
  }
  .subcategory {
    font-size: 0.75rem;
    padding: 8px 4px;
  }
}