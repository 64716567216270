@import "../../global.scss";

.modal-backdrop {
  opacity: 0.8 !important;
}

.itemModal {
  overflow-y: hidden;

  .modal-dialog {
    margin: auto;

    .modal-content {
      display: flex;
      flex-direction: column;
      height: 100dvh;

      .modal-body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 2rem);
        padding: 0;

        .modal-title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex-shrink: 0;
          padding: 0.15rem 0;
          background-color: #ddd;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          .itemModal_name {
            font-size: 1.55rem;
            font-weight: 900;
            padding: 0.6rem 0.5rem;
            background-color: #fff;
            font-family: 'Verdana', sans-serif;
            letter-spacing: -1px;
          }

          .itemModal_title {
            font-weight: 700;
          }

          .close {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            border-bottom: 1px solid #ccc;
            padding: 0.2rem 0.75rem;

            svg {
              width: 100%;
              color: red;
              height: 2rem;
              width: 2rem;
              justify-content: flex-end;
            }
          }
        }

        .itemModal_Content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }

        .itemModal_body {
          flex: 1;
          background-color: white;
          overflow-y: auto;

          .image_wrapper {
            width: 100%;
            max-height: 200px;
            overflow: hidden;

            .item_img {
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .item_details {
            padding: 0rem 0.5rem;
            padding-top: 0.5rem;

          }

          .itemModal_optionsSection {
            width: 100%;
            margin-top: 0.75rem;
            border: 1px solid black;
            border-radius: 7px;

            .required_tab {
              font-size: 0.8rem;
              border: 2px solid gray;
              border-radius: 5px;
              background-color: $mainGray;
              color: black;
              padding: 0rem 0.25rem;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.5rem;
            }
          }

          .itemModal_header {
            border-bottom: 1px solid black;
            color: black;
            background-color: #eee;
            padding: 0.5rem 0.4rem;

            .expand_collapse_button {
              // font-size: 20px;

              svg {
                height: 24px;
                width: 24px;
              }
            }

            .header {
              font-weight: 700;
              font-size: 0.95rem;
            }
          }

          .itemModal_header.no-bottom-radius {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }

          .header_preSelected {
            padding: 0.5rem 0.5rem;
          }

          .pre_selected {
            background-color: $mainRedLight !important;
            border: 3px solid $mainRed !important;
            color: white;
            padding: 0.35rem;
            margin: 0.5rem 0.25rem;
            font-size: 0.9rem;
          }

          .itemModal_specialRequests {
            border-top: 2px solid #ddd;
            padding: 0rem;

            .head {
              margin: 0.5rem 0;
              margin-bottom: 0.5rem;
              font-weight: 700;
              font-size: 1rem;
            }

            .itemRequests_input {
              border: 1px solid black;
              border-radius: 5px;
              font-size: 12px;
            }
          }

          .description {
            font-size: 1rem;
            color: #777;
            font-weight: 700;
            margin: 0;
            margin-bottom: 0.25rem;
            padding-bottom: 0.25rem;
            border-bottom: 2px solid #ccc;
          }
          .days {
            font-size: 0.95rem;
            color: #777;
            font-weight: 700;
            margin: 0 0;
            margin-bottom: 0.5rem;
            padding: 0.25rem 0;
            border-bottom: 2px solid #ddd;
          }

          .itemModal_quantity {
            align-items: center;
            background-color: #eee !important;
            margin-top: 0.5rem;
            padding: 0.25rem 0.5rem;

            .quantity {
              margin-bottom: 0;
              font-size: 1.25rem;
              font-weight: 700;
            }

            .current_number {
              font-size: 1.5rem;
            }

            .sub {
              svg:hover {
                color: red;
              }
            }

            .add {
              svg:hover {
                color: red;
              }
            }
          }

          .itemModal_options {
            padding: 0.25rem;

            .extra_code_container {
              justify-content: space-between;
              margin: 0 0.75rem 0 0.25rem;
              display: flex;
              align-items: center;

              .required_tab {
                font-size: 0.7rem;
                border: 2px solid gray;
                border-radius: 5px;
                background-color: $mainGray;
                color: black;
                padding: 0rem 0.25rem;
              }
            }

            .extraOptions {
              .option_choice_pizza {
                border-radius: 5px;
                border: 1px solid black;

                div {
                  font-size: 0.9rem;
                }
              }

              .option_choice {
                border-radius: 5px;
                padding: 0.425rem 0;
                border: 1px solid black;

                div {
                  font-size: 0.85rem;
                }
              }

              .options {
                .FirstHalf {
                  border: 2px double #000;
                  border-radius: 150px 0 0 150px;
                  width: 1.5rem;
                  cursor: pointer;
                }

                .Full {
                  border: 2px double #000;
                  border-radius: 100%;
                  width: 2rem;
                  cursor: pointer;
                }

                .SecondHalf {
                  border: 2px double #000;
                  border-radius: 0 150px 150px 0;
                  width: 1.5rem;
                  cursor: pointer;
                }

                .Active {
                  background-color: $mainRedLight;
                }
              }

              .Active {
                background-color: $mainRedLight;
                border: $mainRed solid 2px;
                color: white;
              }
            }

            .Active {
              background-color: $mainRedLight !important;
              border: 3px solid $mainRed !important;
              color: white;
            }
          }
        }

        .itemModal_Submit {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: #eee;
          border-top: 2px solid #ccc;
          padding: 0.25rem 0.5rem;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .itemModal_totalPrice {
            font-size: 1.1rem;
            background-color: $mainGray;
            border-radius: 5px;
            border: 2px solid black;
            display: flex;
            justify-content: center;
            color: black;
            font-weight: 800;
            height: 26%;
            align-items: center;
            margin-bottom: 0.25rem;
          }

          button {
            color: white;
            font-size: 1.4rem;
            font-weight: 700;
            width: 100%;
            background-color: $mainGreenLight;
            border-radius: 10px;
            border: 8px solid $mainGreen;
            height: 75px;
          }
        }
      }
    }
  }
}

.request_notice {
  font-size: 10px;
}